import React from 'react';
import Checkboxes from './Checkboxes';
import { Button } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
// import { sizing } from '@material-ui/system';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
// import { spacing } from '@material-ui/system';
// import AppBar from './AppBar';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import theme from './ui/Theme';
import Header from './ui/Header';
import Footer from './ui/Footer';
// import Header from '../components/ui/Header';
// import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
// import yellow from '@material-ui/core/colors/yellow';
// import HUE from '@material-ui/core/colors/HUE';

// const styles = theme => ({
//   myCustomClass: {
//     color: 'red',
//   },
// });

// const useStyles = withStyles(theme => ({
//   root: { padding: '60' },
// }));

// const classes = useStyles();

const defaultInputText = `garlic press rocker
garlic presser
oxo garlic press
garlic press joseph
garlic presses
garlic press oxo
garlic press and slicer
garlic press and peeler
garlic press amazon
garlic press aluminium
garlic press alpha grillers
a garlic press
garlic press brabantia
garlic press brush
garlic press barry
garlic press box
garlic press bowl
garlic press cooking tool
garlic press chopper
garlic press cleaner
garlic press cleaning tool
garlic press dreamfarm
garlic press dishwasher
garlic press designer
garlic press dicer
garlic press iskm
garlic press imucci
garlic press stainless
garlic press stainless steel rocker
garlic press tool
garlic press twist
garlic press taylor eye
garlic press uk
garlic press vampire
garlic press wmf
garlic press with cleaner
garlic press with cherry pitter
garlic press wooden
garlic press with scraper
garlic press yoboo
garlic press yellow
garlic press zyliss
garlic press zwilling
garlic press 4 in 1
2 in 1 garlic press
garlic press 2 in 1
handy garlic press, 2 blades
zyliss susi 3 garlic press
warmday 520 garlic press
go cook garlic press from tesco 9.99`;

const useStyles = theme => ({
  marginButton: {
    // margin: theme.spacing(0),
    marginRight: 10,
  },
  styledLine: {
    color: 'red',
  },
});

// TODO:

// const arr = ['big bad wolf dog', 'go home', 'cat dog fish bird'];
let res = '';
let res1 = '';

// TODO:

class App extends React.Component {
  state = {
    inputText: '',
    outputText: '',
    dedupeStatus: false,
    removeNumbersStatus: false,
    lowercaseStatus: false,
    onewordperlineStatus: false,
    addcommasStatus: false,
    addcommasspaceStatus: false,
    startwordsStatus: '',
    endwordsStatus: '',
    sortalphaStatus: 'Z-A',
    sortlengthStatus: 'LENGTH',
    sortdefaultStatus: 'DEFAULT',
    sorttypeStatus: 'default',
    wordjumblerStatus: false,
  };
  inputArray;
  cleanText;
  inputTextTmp;

  parseInput = e => {
    // alert(this.state.inputText);
    this.cleanText = this.state.inputText;

    //Apply checkbox processing
    this.cleanText = this.checkboxProcessing(this.cleanText);
    // alert(this.cleanText);

    //Turn into single rows
    this.setState({ outputText: this.cleanText.split(' ').join('\n') });
  };

  //*****************************
  // Routine to process text based on checkbox flags
  //*****************************
  inputToOutput = e => {
    //Put entire routine in here

    // Add input keywords to string variable
    this.inputTextTmp = this.state.inputText;

    // Clean Text by removing excess whitespace
    this.inputTextTmp = this.trimSpaces(this.inputTextTmp);

    // Word Jumbler checkbox
    if (this.state.wordjumblerStatus === true) {
      this.inputTextTmp = this.wordJumbler(this.inputTextTmp);
    }

    // Convert to lowercase checkbox
    if (this.state.lowercaseStatus === true) {
      this.inputTextTmp = this.convertToLowercase(this.inputTextTmp);
    }

    // Remove numbers checkbox
    if (this.state.removeNumbersStatus === true) {
      this.inputTextTmp = this.removeNumbers(this.inputTextTmp);
    }
    // One word per line checkbox
    if (this.state.onewordperlineStatus === true) {
      this.inputTextTmp = this.oneWordPerLine(this.inputTextTmp);
    }

    // Add commas checkbox
    if (this.state.addcommasStatus === true) {
      this.inputTextTmp = this.addCommas(this.inputTextTmp);
    }

    // Add commas space checkbox
    if (this.state.addcommasspaceStatus === true) {
      this.inputTextTmp = this.addCommasSpace(this.inputTextTmp);
    }

    // Add start words
    // if (this.state.startwordStatus !== '') {
    this.inputTextTmp = this.addStartWords(this.inputTextTmp);
    // }

    // Add end words
    // if (this.state.startwordStatus !== '') {
    this.inputTextTmp = this.addEndWords(this.inputTextTmp);
    // }

    // Clean Text by removing excess whitespace
    this.inputTextTmp = this.trimSpaces(this.inputTextTmp);

    // Sort default
    // if (this.state.sorttypeStatus === 'default') {
    //   this.inputTextTmp = this.sortDefault(this.inputTextTmp, 'DEFAULT');
    // }

    // Sort alpha toggle
    if (
      this.state.sortalphaStatus === 'A-Z' &&
      this.state.sorttypeStatus === 'alpha'
    ) {
      this.inputTextTmp = this.sortAlpha(this.inputTextTmp, 'A-Z');
    } else if (
      this.state.sortalphaStatus === 'Z-A' &&
      this.state.sorttypeStatus === 'alpha'
    ) {
      this.inputTextTmp = this.sortAlpha(this.inputTextTmp, 'Z-A');
    }
    //if (this.state.sortalphaStatus === 'Z-A')

    // Sort length toggle
    if (
      this.state.sortlengthStatus === 'LENGTH' &&
      this.state.sorttypeStatus === 'length'
    ) {
      this.inputTextTmp = this.sortLength(this.inputTextTmp, 'LENGTH');
    } else if (
      this.state.sortlengthStatus === 'LENGTH*' &&
      this.state.sorttypeStatus === 'length'
    ) {
      this.inputTextTmp = this.sortLength(this.inputTextTmp, 'LENGTH*');
    }

    // Clean Text by removing blank lines
    this.inputTextTmp = this.inputTextTmp.replace('\n\n', '\n');
    // this.inputTextTmp = this.inputTextTmp.split('\n').join('\n,');

    // this.inputTextTmp = this.inputTextTmp
    //   .split('\n')
    //   .sort(function (a, b) {
    //     return a - b;
    //   })
    //   .join('\n');

    // Trim spaces again
    this.inputTextTmp = this.trimSpacesStartEnd(this.inputTextTmp);

    this.inputTextTmp = this.inputTextTmp
      .split('\n')
      .filter(v => v !== '')
      .join('\n');

    // Remove Duplicates checkbox

    if (this.state.dedupeStatus === true) {
      this.inputTextTmp = this.removeDuplicates(this.inputTextTmp);
    }

    // Populate Output Keywords box
    this.setState({ outputText: this.inputTextTmp });
  };
  //*****************************

  checkboxProcessing = str => {
    // Remove duplicates
    str = this.removeNumbers(str);
    if (this.state.dedupe === true) {
      str = this.removeDuplicates(str);
    }
    return str;
  };

  // ********************************
  // Change state based on checkBoxes
  // ********************************

  handleInputText = e => {
    // if (this.state.inputText === '') {
    this.setState({ inputText: e.target.value });
  };

  handleDedupe = () => {
    this.setState({ dedupeStatus: !this.state.dedupeStatus }, () => {
      this.inputToOutput();
    });
  };

  handleRemoveNumbers = () => {
    this.setState(
      { removeNumbersStatus: !this.state.removeNumbersStatus },
      () => {
        this.inputToOutput();
      }
    );
  };

  handleConvertToLowercase = () => {
    this.setState({ lowercaseStatus: !this.state.lowercaseStatus }, () => {
      this.inputToOutput();
    });
  };

  handleOneWordPerLine = () => {
    this.setState(
      { onewordperlineStatus: !this.state.onewordperlineStatus },
      () => {
        this.inputToOutput();
      }
    );
  };

  handleAddCommas = () => {
    this.setState({ addcommasStatus: !this.state.addcommasStatus }, () => {
      this.inputToOutput();
    });
  };

  handleAddCommasSpace = () => {
    this.setState(
      { addcommasspaceStatus: !this.state.addcommasspaceStatus },
      () => {
        this.inputToOutput();
      }
    );
  };

  handleStartWords = e => {
    this.setState({ startwordsStatus: e.target.value }, () => {
      this.inputToOutput();
    });
  };

  handleEndWords = e => {
    this.setState({ endwordsStatus: e.target.value }, () => {
      this.inputToOutput();
    });
  };

  handleSortDefault = () => {
    this.setState(
      {
        // sortalphaStatus: this.state.sortalphaStatus === 'A-Z' ? 'Z-A' : 'A-Z',
        sorttypeStatus: 'default',
      },
      () => {
        // this.setState({ sortalphaStatus: !this.state.sortalphaStatus }, () => {
        this.inputToOutput();
      }
    );
  };

  handleSortAlpha = () => {
    this.setState(
      {
        sortalphaStatus: this.state.sortalphaStatus === 'A-Z' ? 'Z-A' : 'A-Z',
        sorttypeStatus: 'alpha',
      },
      () => {
        // this.setState({ sortalphaStatus: !this.state.sortalphaStatus }, () => {
        this.inputToOutput();
      }
    );
  };

  handleSortLength = () => {
    this.setState(
      {
        sortlengthStatus:
          this.state.sortlengthStatus === 'LENGTH' ? 'LENGTH*' : 'LENGTH',
        sorttypeStatus: 'length',
      },
      () => {
        // this.setState({ sortalphaStatus: !this.state.sortalphaStatus }, () => {
        this.inputToOutput();
      }
    );
  };

  handleSetDummyText = () => {
    // this.setState({ inputText: defaultInputText }, () => {
    //   this.inputToOutput();
    // });
    this.setState({ inputText: defaultInputText });
    // alert(this.state.inputText);
  };

  handleWordJumbler = () => {
    this.setState({ wordjumblerStatus: !this.state.wordjumblerStatus }, () => {
      this.inputToOutput();
    });
  };

  // ********************************
  // Functions to process the text
  // ********************************

  removeDuplicates(tmpStr) {
    // this.setState({ outputText: new Set(this.state.outputText) });
    //Turn string into array (temporarily)
    // let tmpArray = tmpStr.split(/\s+/);

    let tmpArray = tmpStr.split('\n');

    //Create new array wtihtout dupes
    tmpArray = [...new Set(tmpArray)];

    // tmpArray = tmpArray.filter(function (el) {
    //   return el != null;
    // });

    tmpStr = tmpArray.join('\n');

    //Turn back into string
    return tmpStr;
  }

  removeNumbers(tmpStr) {
    // tmpStr = tmpStr.replace(/[0-9]/g, '');

    //Previously working - pending new try for dot
    // tmpStr = tmpStr.replace(/\d+/g, '');

    // New version that takes out dot too
    tmpStr = tmpStr.replace(/\d+.\d+/g, '');
    tmpStr = tmpStr.replace(/\d+/g, '');
    // tmpStr = tmpStr
    //   .split(',')
    //   .map(s => s.trim())
    //   .join('\n');

    //Remove blank lines
    // tmpStr = tmpStr.replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm, '');

    return tmpStr;
  }

  convertToLowercase(tmpStr) {
    tmpStr = tmpStr.toLowerCase();
    return tmpStr;
  }

  oneWordPerLine(tmpStr) {
    tmpStr = tmpStr.split(' ').join('\n');
    return tmpStr;
  }

  clearOutput = () => {
    this.setState({ outputText: '' });
  };
  h;

  clearInput = () => {
    this.setState({ inputText: '' });
    // this.inputTextTmp = '';
    // this.inputToOutput();
  };

  parseToSingleWords = () => {
    this.setState({ outputText: this.inputArray.join('\n') });
  };

  addCommas = tmpStr => {
    // tmpStr = tmpStr.replace('\n', '\n,');
    tmpStr = tmpStr.split('\n').join(',');

    //Remove blank lines
    // tmpStr = tmpStr.replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm, '');

    return tmpStr;
    // this.setState({ outputText: this.inputArray.join('\n') });
  };

  addCommasSpace = tmpStr => {
    tmpStr = tmpStr.split('\n').join(', ');

    return tmpStr;
  };

  removeWhiteSpaceFromArray(array) {
    return array.filter(item => item !== ' ');
  }

  // Add start words
  addStartWords = tmpStr => {
    // tmpStr = tmpStr.replace('\n', '\n,');
    // alert(this.state.startwordsStatus);
    // tmpStr = (this.state.startwordsStatus + tmpStr.split('\n')).join(',');
    // tmpStr = startWords AND tmpStr.split('\n').join(',' );

    tmpStr =
      this.state.startwordsStatus +
      tmpStr.replaceAll('\n', `\n${this.state.startwordsStatus}`);

    return tmpStr;
  };

  addEndWords = tmpStr => {
    // tmpStr = tmpStr.replace('\n', '\n,');
    // alert(this.state.startwordsStatus);
    // tmpStr = (this.state.startwordsStatus + tmpStr.split('\n')).join(',');
    // tmpStr = startWords AND tmpStr.split('\n').join(',' );

    tmpStr =
      tmpStr.replaceAll('\n', `${this.state.endwordsStatus}\n`) +
      this.state.endwordsStatus;

    return tmpStr;
  };

  // ********************************
  // Functions to Sort the text
  // ********************************

  sortAlpha = (tmpStr, status) => {
    if (status === 'A-Z') {
      tmpStr = tmpStr
        .split('\n')
        .map(s => s.trim())
        .sort((a, b) => {
          if (a.toLowerCase() < b.toLowerCase()) return -1;
          if (a.toLowerCase() > b.toLowerCase()) return 1;
          return 0;
          // a.toLowerCase().localCompare(b.toLowerCase());
        })
        .join('\n');
      // tmpStr = tmpStr.split('\n').sort().join('\n');
    } else {
      // tmpStr = tmpStr;
      // .split('\n')
      // .map(s => s.trim())
      // .join('\n');

      // tmpStr = tmpStr
      //   .split('\n')
      //   .reverse()

      //   .join('\n');
      // tmpStr = tmpStr.split('\n').sort().reverse().join('\n');

      tmpStr = tmpStr
        .split('\n')
        .map(s => s.trim())
        .sort((a, b) => {
          if (a.toLowerCase() > b.toLowerCase()) return -1;
          if (a.toLowerCase() < b.toLowerCase()) return 1;
          return 0;
          // a.toLowerCase().localCompare(b.toLowerCase());
        })
        .join('\n');
    }

    // this.setState((this.sortlengthStatus: ''));

    return tmpStr;
  };

  sortLength = (tmpStr, status) => {
    if (status === 'LENGTH') {
      tmpStr = tmpStr
        .split('\n')
        .sort((a, b) => b.length - a.length)
        .join('\n');
    } else {
      // tmpStr = tmpStr.split('\n').sort().reverse().join('\n');
      tmpStr = tmpStr
        .split('\n')
        .sort((a, b) => a.length - b.length)
        .join('\n');
    }
    // this.setState((this.sortalphaStatus: ''));
    return tmpStr;
  };

  wordJumbler = myArray => {
    res = '';
    res1 = '';
    myArray = myArray.split('\n');
    myArray.forEach(x => {
      res = this.getPermutations(x);
      res1 = res + ',' + res1;
    });
    // console.log(res1);
    res1 = res1.split(',').join('\n');
    // console.log(res1);
    return res1;
  };

  swap = (words, i, j) => {
    let tmp = words[i];
    words[i] = words[j];
    words[j] = tmp;
  };

  getPermutations = input => {
    let counter = [],
      permutations = [],
      words = input.split(' '),
      length = words.length,
      i;

    for (i = 0; i < length; i++) {
      counter[i] = 0;
    }

    permutations.push(input);
    i = 0;
    while (i < length) {
      if (counter[i] < i) {
        this.swap(words, i % 2 === 1 ? counter[i] : 0, i);
        counter[i]++;
        i = 0;
        permutations.push(words.join(' '));
      } else {
        counter[i] = 0;
        i++;
      }
    }

    return permutations;
  };

  countWords = str => {
    // str = str.replace(/(^\s*)|(\s*$)/gi, '');
    // str = str.replace(/[ ]{2,}/gi, ' ');
    // str = str.replace(/\n /, '\n');
    // return str.split(' ').length;
    return str.trim().split(/\s+/).length;
  };

  trimSpaces = str => {
    return str.replace(/ {1,}/g, ' ').trim();
    // return str.replace(/\s+/g, ' ').trim();
  };

  trimSpacesStartEnd = str => {
    str = str
      .split('\n')
      .map(s => s.trim())
      .join('\n');
    return str;
  };

  characterCount = str => {
    str = str.replace();
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <Header />
            <Switch>
              {/* <Route exact path="/" component={() => <div>Home</div>} /> */}
              <Route
                exact
                path="/services"
                component={() => <div>Services</div>}
              />
              <Route
                exact
                path="/customsoftware"
                component={() => <div>Custom Software</div>}
              />
              <Route
                exact
                path="/mobileapps"
                component={() => <div>Mobile Apps</div>}
              />
              <Route
                exact
                path="/websites"
                component={() => <div>Websites</div>}
              />
              <Route
                exact
                path="/revolution"
                component={() => <div>Revolution</div>}
              />
              <Route exact path="/about" component={() => <div>About</div>} />
              <Route
                exact
                path="/contact"
                component={() => <div>Contact</div>}
              />
              <Route
                exact
                path="/estimate"
                component={() => <div>Estimate</div>}
              />
            </Switch>
          </BrowserRouter>
        </ThemeProvider>

        <ThemeProvider theme={theme}>
          <Grid container justify="center">
            <Box mt={0} width={1100}>
              <Paper>
                <Box p={3} mt={2} mb={2}>
                  <Grid container spacing={0}>
                    <Grid item xs={6}>
                      <Typography
                        variant="h5"
                        gutterBottom={false}
                        onClick={this.handleSetDummyText}
                      >
                        Keyword Builder
                      </Typography>
                    </Grid>

                    <Grid item xs={6} align="right">
                      {/* ********* Offending code section ********** */}
                      <Button
                        color={
                          this.state.sorttypeStatus === 'default'
                            ? 'primary'
                            : 'secondary'
                        }
                        onClick={this.handleSortDefault}
                      >
                        UNSORTED
                      </Button>
                      {/* ********* Offending code section ********** */}
                      <Button
                        color={
                          this.state.sorttypeStatus === 'alpha'
                            ? 'primary'
                            : 'secondary'
                        }
                        onClick={this.handleSortAlpha}
                      >
                        A-Z
                      </Button>
                      {/* <Button
                        color={
                          this.state.sorttypeStatus === 'length'
                            ? 'primary'
                            : 'secondary'
                        }
                        onClick={this.handleSortLength}
                      >
                        LENGTH
                      </Button> */}
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        {/* TODO: */}
                        <Grid
                          xs={12}
                          container
                          justify="flex-start"
                          // style={{ marginLeft: 16 }}
                        >
                          <Typography>
                            Rows:{' '}
                            {this.state.inputText === ''
                              ? 0
                              : this.state.inputText.split('\n').length}
                            &nbsp;&nbsp;&nbsp;
                          </Typography>
                          <Typography>
                            Words: {this.countWords(this.state.inputText)}
                            &nbsp;&nbsp;&nbsp;
                          </Typography>

                          <Typography>
                            Characters:{' '}
                            {
                              this.state.inputText.replace(/(\r\n|\n|\r)/gm, '')
                                .length
                            }
                          </Typography>
                        </Grid>
                        <TextField
                          label="Input Keywords"
                          fullWidth
                          multiline
                          rows={10}
                          variant="outlined"
                          margin="normal"
                          onChange={this.handleInputText}
                          // defaultValue={defaultInputText}
                          value={this.state.inputText}
                        />
                        <Box m={0}>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.marginButton}
                            onClick={this.inputToOutput}
                          >
                            Build
                          </Button>

                          <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.marginButton}
                            onClick={this.clearInput}
                            // default={this.state.inputText}
                          >
                            Clear
                          </Button>
                        </Box>
                        <br />
                        <Checkboxes
                          handleDedupe={this.handleDedupe}
                          handleRemoveNumbers={this.handleRemoveNumbers}
                          handleConvertToLowercase={
                            this.handleConvertToLowercase
                          }
                          handleOneWordPerLine={this.handleOneWordPerLine}
                          handleAddCommas={this.handleAddCommas}
                          handleAddCommasSpace={this.handleAddCommasSpace}
                          handleStartWords={this.handleStartWords}
                          handleEndWords={this.handleEndWords}
                          handleWordJumbler={this.handleWordJumbler}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Grid
                          xs={12}
                          container
                          justify="flex-start"
                          // style={{ marginLeft: 16 }}
                        >
                          <Typography>
                            Rows:{' '}
                            {this.state.outputText === ''
                              ? 0
                              : this.state.outputText.split('\n').length}
                            &nbsp;&nbsp;&nbsp;
                          </Typography>
                          <Typography>
                            Words: {this.countWords(this.state.outputText)}
                            &nbsp;&nbsp;&nbsp;
                          </Typography>

                          <Typography>
                            Characters:{' '}
                            {
                              this.state.outputText.replace(
                                /(\r\n|\n|\r)/gm,
                                ''
                              ).length
                            }
                          </Typography>
                        </Grid>
                        <TextField
                          label="Output Keywords"
                          fullWidth
                          multiline
                          rows={30}
                          variant="outlined"
                          margin="normal"
                          value={this.state.outputText}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.marginButton}
                          mt={5}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              this.state.outputText
                            );
                          }}
                        >
                          Copy
                        </Button>
                        <Button
                          variant="outlined"
                          color="secondary"
                          className={classes.marginButton}
                          mt={5}
                          onClick={this.clearOutput}
                        >
                          Clear
                        </Button>
                        <Button
                          variant="outlined"
                          color="secondary"
                          className={classes.marginButton}
                          mt={5}
                          onClick={this.handleSetDummyText}
                        >
                          Dummy Data
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Box>
          </Grid>
          <Footer />
        </ThemeProvider>
      </>
    );
  }
}

export default withStyles(useStyles)(App);
