import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import { spacing } from '@material-ui/system';
import { makeStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles(theme => ({
  marginButton: {
    // margin: theme.spacing(0),
    marginRight: 10,
  },
  styledLine: {
    color: 'red',
  },
}));

export default function CheckboxLabels({
  handleDedupe,
  handleRemoveNumbers,
  handleConvertToLowercase,
  handleOneWordPerLine,
  handleAddCommas,
  handleAddCommasSpace,
  handleStartWords,
  handleEndWords,
  handleWordJumbler,
}) {
  const classes = useStyles();
  return (
    <FormGroup>
      {/* <FormControlLabel
        control={<Checkbox name="removewhitespace" />}
        label="Remove Whitespace"
      /> */}
      <FormControlLabel
        control={<Checkbox name="onewordperline" />}
        label="One word per line"
        onChange={handleOneWordPerLine}
      />
      <FormControlLabel
        control={<Checkbox name="removenumbers" />}
        label="Remove Numbers"
        onChange={handleRemoveNumbers}
      />
      <FormControlLabel
        control={<Checkbox name="removeduplicates" />}
        label="Remove Duplicates"
        onChange={handleDedupe}
      />
      <FormControlLabel
        control={<Checkbox name="converttolowercase" />}
        label="Convert to lowercase"
        onChange={handleConvertToLowercase}
      />
      <FormControlLabel
        control={<Checkbox name="addcommas" />}
        label="Add commas (CSV format)"
        onChange={handleAddCommas}
      />
      <FormControlLabel
        control={<Checkbox name="addcommasspace" />}
        label="Add commas + space"
        onChange={handleAddCommasSpace}
      />

      <Grid container direction="row" alignItems="center">
        <FormControlLabel
          control={<Checkbox name="wordjumbler" />}
          label="Word Jumbler"
          onChange={handleWordJumbler}
        />
        <Tooltip title="Word Jumbler takes all the words on a row and creates new rows based on every possible combination">
          <HelpOutlineIcon style={{ color: '#cccccc' }} />
        </Tooltip>
      </Grid>

      <form>
        <Box mt={2}>
          <TextField
            mt={10}
            // id="outlined-basic"
            label="Add words to start"
            variant="outlined"
            className={classes.marginButton}
            onChange={handleStartWords}
          />
          <TextField
            mt={10}
            // id="outlined-basic"
            label="Add words to end"
            variant="outlined"
            className={classes.marginButton}
            onChange={handleEndWords}
          />
        </Box>
      </form>
    </FormGroup>
  );
}
